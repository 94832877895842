<template>
  <div>
    <b-form-group
      label="Notlar"
      label-for="notes"
    >
      <b-form-textarea
        id="notes"
        v-model="dataItem.notes"
        placeholder="Notlar"
      />
    </b-form-group>
    <b-alert show>
      <div class="alert-body text-center">
        <FeatherIcon icon="InfoIcon" />
        Notlar alanı gizlidir. Müşteriye gösterilmez.
      </div>
    </b-alert>
  </div>
</template>

<script>
import { BFormGroup, BFormTextarea, BAlert } from 'bootstrap-vue'

export default {
  name: 'Notes',
  components: {
    BFormGroup,
    BFormTextarea,
    BAlert,
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
  },
}
</script>
