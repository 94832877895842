<template>
  <b-form-group
    label="Miktar"
    :label-for="'quantity_' + itemKey"
  >
    <validation-provider
      #default="{ errors }"
      name="Miktar"
      rules="required"
    >
      <cleave
        :id="'quantity_' + itemKey"
        v-model="dataItem.offer_lines[itemKey].quantity"
        class="form-control"
        :raw="true"
        :options="options.float"
        placeholder="Miktar"
        @input="calcAction(itemKey)"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import Cleave from 'vue-cleave-component'

export default {
  name: 'Quantity',
  components: {
    BFormGroup,
    Cleave,
    ValidationProvider,
  },
  props: {
    itemKey: {
      type: Number,
      required: true,
    },
    calcAction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      options: {
        float: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '',
        },
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
