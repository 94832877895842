<template>
  <b-form-group
    label="Teklif No"
    label-for="onumber"
  >
    <b-form-input
      id="onumber"
      v-model="dataItem.onumber"
      placeholder="Teklif No"
      readonly
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  name: 'OfferNo',
  components: {
    BFormGroup,
    BFormInput,
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
  },
}
</script>
