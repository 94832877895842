<template>
  <b-row>
    <b-col v-if="Number(totalPrice.discountedTotal) < Number(totalPrice.listedTotal)">
      <div class="font-weight-light">
        Toplam İndirimli Tutar <small>(KDV Dahil)</small>
      </div>
      <div class="font-medium-4 font-weight-bolder text-success">
        {{ totalPrice.discountedTotal | toCurrency }}
        {{ this.dataItem.offer_lines[0].currency }}
      </div>
    </b-col>
    <b-col>
      <div class="font-weight-light">
        Toplam Tutar <small>(KDV Dahil)</small>
      </div>
      <div
        class="font-medium-4 font-weight-bolder"
        :class="Number(totalPrice.discountedTotal) < Number(totalPrice.listedTotal)? 'strikethrough' : ''"
      >
        {{ totalPrice.listedTotal | toCurrency }}
        {{ this.dataItem.offer_lines[0].currency }}
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

export default {
  name: 'GrandTotal',
  components: {
    BRow, BCol,
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
    totalPrice() {
      return this.grandTotal()
    },
  },
  methods: {
    grandTotal() {
      let listedTotal = []
      let discountedTotal = []
      const lines = this.dataItem.offer_lines
      lines.forEach(e => {
        listedTotal.push(e.total_price)
        if (e.discounted_price > 0) {
          discountedTotal.push(e.discounted_price)
        } else {
          discountedTotal.push(e.total_price)
        }
      })
      listedTotal = listedTotal.reduce((partialSum, a) => partialSum + a, 0)
      discountedTotal = discountedTotal.reduce((partialSum, a) => partialSum + a, 0)
      return {
        listedTotal,
        discountedTotal,
      }
    },
  },
}
</script>
<style scoped>
.strikethrough{
  text-decoration: line-through !important;
}
</style>
