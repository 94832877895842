<template>
  <b-form-group
    label="İndirim Tutarı"
    :label-for="'discount_amount_' + itemKey"
  >
    <b-input-group>
      <cleave
        :id="'discount_amount_' + itemKey"
        v-model="dataItem.offer_lines[itemKey].discount_amount"
        class="form-control"
        :raw="true"
        :options="options.float"
        placeholder="İndirim Tutarı"
        :disabled="Number(dataItem.offer_lines[itemKey].discount_rate) > 0"
        @input="calcAction(itemKey)"
      />
      <b-input-group-append is-text>
        {{ dataItem.offer_lines[0].currency }}
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'DiscountAmount',
  components: {
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    Cleave,
  },
  props: {
    itemKey: {
      type: Number,
      required: true,
    },
    calcAction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      options: {
        float: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '',
        },
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
  },
}
</script>
