<template>
  <div>
    <b-card title="Teklif Özeti">
      <b-row>
        <b-col>
          <offer-date />
        </b-col>
        <b-col>
          <valid-date />
        </b-col>
        <b-col>
          <offer-no />
        </b-col>
        <b-col cols="12">
          <offer-status :is-new="true" />
        </b-col>
        <b-col cols="12">
          <offer-title />
        </b-col>
        <b-col cols="12">
          <offer-content />
        </b-col>
        <b-col cols="12">
          <notes />
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Ürünler</b-card-title>
      </b-card-header>
      <b-card-body>
        <products />
      </b-card-body>
      <b-card-footer v-if="dataItem.offer_lines.length > 0">
        <grand-total />
      </b-card-footer>
    </b-card>
    <b-card title="Özel Şartlar">
      <offer-terms />
      <offer-term />
    </b-card>
    <save-button
      :save-action="submitForm"
      :is-disabled="submitStatus"
    />
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BCardFooter,
} from 'bootstrap-vue'
import OfferDate from '@/views/Admin/Offers/elements/OfferDate.vue'
import ValidDate from '@/views/Admin/Offers/elements/ValidDate.vue'
import OfferNo from '@/views/Admin/Offers/elements/OfferNo.vue'
import OfferStatus from '@/views/Admin/Offers/elements/OfferStatus.vue'
import OfferTitle from '@/views/Admin/Offers/elements/Title.vue'
import OfferContent from '@/views/Admin/Offers/elements/Content.vue'
import Notes from '@/views/Admin/Offers/elements/Notes.vue'
import Products from '@/views/Admin/Offers/elements/Products.vue'
import GrandTotal from '@/views/Admin/Offers/elements/GrandTotal.vue'
import OfferTerms from '@/views/Admin/Offers/elements/OfferTerms.vue'
import OfferTerm from '@/views/Admin/Offers/elements/OfferTerm.vue'
import SaveButton from '@/layouts/components/common/SaveButton.vue'

export default {
  name: 'OfferForm',
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardFooter,
    OfferDate,
    ValidDate,
    OfferNo,
    OfferStatus,
    OfferTitle,
    OfferContent,
    Notes,
    Products,
    GrandTotal,
    OfferTerms,
    OfferTerm,
    SaveButton,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
    submitStatus: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
  },
}
</script>
