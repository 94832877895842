<template>
  <b-row>
    <b-col
      cols="12"
      md="12"
    >
      <b-form-group
        label="Ürün Grubu"
        :label-for="'id_product_groups_' + itemKey"
      >
        <validation-provider
          #default="{ errors }"
          name="Ürün Grubu"
          rules="required"
        >
          <v-select
            :id="'id_product_groups_' + itemKey"
            v-model="dataItem.offer_lines[itemKey].id_product_groups"
            :options="productGroups"
            label="title"
            :reduce="item => item.id"
            placeholder="Seçiniz"
            @input="changeGroup"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="6"
    >
      <b-form-group
        label="Ürün Kartı"
        :label-for="'id_products_' + itemKey"
      >
        <validation-provider
          #default="{ errors }"
          name="Ürün Kartı"
          rules="required"
        >
          <v-select
            :id="'id_products_' + itemKey"
            v-model="dataItem.offer_lines[itemKey].id_products"
            :options="products"
            label="title"
            :reduce="item => item.id"
            placeholder="Seçiniz"
            :disabled="!products.length"
            @input="getProductMedia"
          >
            <template v-slot:option="option">
              <div class="d-flex">
                <div class="width-100 overflow-hidden rounded">
                  <b-img
                    :src="baseURL + '/media/products/' + option.image"
                    fluid-grow
                  />
                </div>
                <div class="ml-1">
                  <div class="font-weight-bold">
                    {{ option.pcode }} - {{ option.title }}
                  </div>
                  <div class="font-small-2">
                    {{ option.product_group }}
                  </div>
                </div>
              </div>
            </template>
          </v-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="6"
    >
      <b-form-group
        label="Ürün Varyasyonu"
        :label-for="'id_product_media_' + itemKey"
      >
        <validation-provider
          #default="{ errors }"
          name="Ürün Varyasyonu"
          rules="required"
        >
          <v-select
            :id="'id_product_media_' + itemKey"
            v-model="dataItem.offer_lines[itemKey].id_product_media"
            :options="productMedia"
            label="title"
            :reduce="item => item.id"
            placeholder="Seçiniz"
            :disabled="!productMedia.length"
            @input="setTitle"
          >
            <template v-slot:option="option">
              <div
                v-if="option.thumb"
                class="d-flex"
              >
                <div
                  class="width-100 overflow-hidden rounded"
                >
                  <b-img
                    :src="baseURL + '/media/products/' + option.thumb"
                    fluid-grow
                  />
                </div>
                <div class="ml-1">
                  <div class="font-weight-bold">
                    {{ option.title }}
                  </div>
                  <div class="font-small-2">
                    {{ option.product_title }}
                  </div>
                </div>
              </div>
              <div
                v-else
              >
                <div class="height-50 d-flex align-items-center">
                  <div>
                    <div class="font-weight-bold">
                      <FeatherIcon icon="CheckCircleIcon" />
                      {{ option.title }}
                    </div>
                    <div class="font-small-2">
                      Seçili ürün için tüm renk varyasyonlarını ekle
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </v-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, BImg,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'Product',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BImg,
    vSelect,
    ValidationProvider,
  },
  props: {
    itemKey: {
      type: Number,
      required: true,
    },
    idProductGroups: {
      type: String,
      default: null,
    },
    idProducts: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      required,
      baseURL: this.$store.state.app.baseURL,
      products: [],
      productMedia: [],
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
    productGroups() {
      return this.$store.getters['productGroups/dataList']
    },
  },
  created() {
    this.getProductGroups()
    localize('tr')
  },
  mounted() {
    this.processData()
  },
  methods: {
    processData() {
      if (this.idProductGroups) {
        this.getProducts()
      }
      if (this.idProducts) {
        this.getProductMedia()
      }
    },
    getProductGroups() {
      this.$store.dispatch('productGroups/getDataList', {
        select: [
          'product_groups.id AS id',
          'product_groups.title AS title',
        ],
      })
    },
    changeGroup() {
      this.dataItem.offer_lines[this.itemKey].id_products = null
      this.dataItem.offer_lines[this.itemKey].id_product_media = null
      this.getProducts()
    },
    getProducts() {
      if (this.dataItem.offer_lines[this.itemKey].id_product_groups) {
        this.$store.dispatch('products/getDataList', {
          select: [
            'products.id AS id',
            'products.pcode AS pcode',
            'products.title AS title',
            'product_groups.title AS product_group',
          ],
          where: {
            'products.id_product_groups': this.dataItem.offer_lines[this.itemKey].id_product_groups,
          },
        })
          .then(response => {
            if (response) {
              this.products = response
            }
          })
      }
    },
    getProductMedia() {
      if (this.dataItem.offer_lines[this.itemKey].id_products) {
        this.$store.dispatch('productMedia/getDataList', {
          select: [
            'product_media.id AS id',
            'product_media.title AS title',
            'product_media.thumb AS thumb',
            'products.title AS product_title',
          ],
          where: {
            'product_media.id_products': this.dataItem.offer_lines[this.itemKey].id_products,
          },
        })
          .then(response => {
            if (response) {
              this.productMedia = []
              this.productMedia.push({
                id: 'is_batch',
                title: 'Tüm varyasyonlar',
                thumb: null,
              })
              response.forEach(item => {
                this.productMedia.push(item)
              })
            }
          })
      } else {
        this.dataItem.offer_lines[this.itemKey].id_product_media = null
      }
    },
    setTitle() {
      const product = this.products.find(e => e.id === this.dataItem.offer_lines[this.itemKey].id_products)
      const productMedia = this.productMedia.find(e => e.id === this.dataItem.offer_lines[this.itemKey].id_product_media)
      this.dataItem.offer_lines[this.itemKey].title = `${productMedia.title} - ${product.title}`
    },
  },
}
</script>
