<template>
  <div class="bg-light-info p-1 rounded">
    <b-row class="d-flex align-items-center">
      <b-col>
        <div class="font-weight-light">
          İndirimli Tutar <span class="font-small-2 text-info">(KDV Dahil)</span>
        </div>
        <div class="font-medium-3 font-weight-bolder">
          {{ dataItem.offer_lines[itemKey].discounted_price | toCurrency }}
          {{ dataItem.offer_lines[0].currency }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

export default {
  name: 'DiscountedPrice',
  components: {
    BRow, BCol,
  },
  props: {
    itemKey: {
      type: Number,
      required: true,
    },
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
  },
}
</script>
