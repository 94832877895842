<template>
  <b-form-group
    label="Teklif Özel Şartları"
    label-for="id_offer_statuses"
  >
    <v-select
      id="id_offer_terms"
      v-model="id_offer_terms"
      :options="dataList"
      label="title"
      :reduce="item => item.id"
      placeholder="Seçiniz"
      @input="getTerm"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'OfferTerms',
  components: {
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      id_offer_terms: null,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
    dataList() {
      return this.$store.getters['offerTerms/dataList']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getTerm() {
      if (this.id_offer_terms) {
        this.$store.dispatch('offerTerms/getDataItem', this.id_offer_terms)
          .then(response => {
            this.dataItem.terms = response.content
          })
      }
    },
    getData() {
      this.$store.dispatch('offerTerms/getDataList', {
        select: [
          'offer_terms.id AS id',
          'offer_terms.title AS title',
        ],
      })
    },
  },
}
</script>
