<template>
  <b-form-group
    label="Vergi Oranı"
    :label-for="'id_taxes_' + itemKey"
  >
    <validation-provider
      #default="{ errors }"
      name="Vergi Oranı"
      rules="required"
    >
      <v-select
        :id="'id_taxes_' + itemKey"
        v-model="dataItem.offer_lines[itemKey].id_taxes"
        :options="taxes"
        label="title"
        :reduce="item => item.id"
        placeholder="Seçiniz"
        @input="setRate"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'Taxes',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  props: {
    itemKey: {
      type: Number,
      required: true,
    },
    calcAction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
    taxes() {
      return this.$store.getters['taxes/dataList']
    },
  },
  created() {
    this.getData()
    localize('tr')
  },
  methods: {
    getData() {
      this.$store.dispatch('taxes/getDataList', {
        select: [
          'taxes.id AS id',
          'taxes.title AS title',
          'taxes.rate AS rate',
        ],
      })
    },
    setRate() {
      if (this.dataItem.offer_lines[this.itemKey].id_taxes) {
        this.dataItem.offer_lines[this.itemKey].tax_rate = this.taxes.find(e => e.id === this.dataItem.offer_lines[this.itemKey].id_taxes).rate
      } else {
        this.dataItem.offer_lines[this.itemKey].tax_rate = null
      }
      this.calcAction(this.itemKey)
    },
  },
}
</script>
